/*Servicemenü*/
.servicemenu {
	right: 0px;
	position: fixed;
	top: 290px;
	z-index: 980;
}

.sidebar_list {
	float: left;
	width:54px;
}

.servicemenu  ul{
	margin: -2px;
	padding: 0px;
}

.servicemenu ul li{
	background-color:  #cc0000;
	width: 53px;
	height: 52px;
	list-style: none;
	margin: 2px;
	padding-top: 2px;
	cursor: pointer;
	background-color: #cc0000;
	background-position: 0px 0px;
}

.servicemenu .activated_mouseover ul li{
	float: right;
	clear: both;
}


.servicemenu ul li {
	background-color: #cc0000;
	background-image: url(../../images_wl2/icon-sprites.png);
}

.servicemenu ul li.active,
.servicemenu .disabled_mouseover li:hover,
.servicemenu.openIFrame ul li:hover{
	background-color: #e7e7e7;
	background-position: 0px 54px;
}

.servicemenu ul li a{
	display: block;
}

.servicemenu .disabled_mouseover ul li a{
	width: 53px;
	height: 52px;
}

.servicemenu .activated_mouseover ul li a{
	width: 100%;
	height: 100%;
}

.servicemenu ul li a span{
	text-overflow: initial;
	white-space: nowrap;
	display: inline;
    float: right;
	width: 0px;
    height: 100%;
    color: white;
	background-color:  #cc0000;
	text-align: left;
	text-transform: uppercase;
    font-size: 150%;
    font-family: wuerthextra_bold_cond, Arial, Helvetica, "Microsoft Yahei", "微软雅黑", sans-serif;
    line-height: 53px;
    overflow: hidden;
}

.servicemenu .disabled_mouseover ul li a span{
	display: none;
}

.servicemenu .activated_mouseover ul li.active a span{
	background-color: #e7e7e7;
	color: #000;
}

.servicemenu ul li.cart {
	background-position: 2px -120px;
}

.servicemenu .disabled_mouseover ul li.cart:hover,
.servicemenu.openIFrame .activated_mouseover ul li.cart:hover{
	background-position: 2px -170px;
}

.servicemenu ul li.contact {
	background-position: -55px -120px;
}

.servicemenu ul li.contact.active,
.servicemenu .disabled_mouseover ul li.contact:hover,
.servicemenu.openIFrame .activated_mouseover ul li.contact:hover{
	background-position: -55px -170px;
}

.servicemenu ul li.chat:not(#hk_chat) {
	background-position: -108px -120px;
}

.servicemenu ul li.chat.active:not(#hk_chat),
.servicemenu .disabled_mouseover ul li.chat:hover:not(#hk_chat),
.servicemenu.openIFrame .activated_mouseover ul li.chat:hover:not(#hk_chat){
	background-position: -108px -170px;
}

.servicemenu ul li.services {
	background-position: -160px -120px;
}

.servicemenu ul li.services.active,
.servicemenu .disabled_mouseover ul li.services:hover,
.servicemenu.openIFrame .activated_mouseover ul li.services:hover{
	background-position: -160px -170px;
}

.servicemenu ul li.blog {
      background-position: -292px -117px;
}

.servicemenu ul li.blog.active,
.servicemenu .disabled_mouseover ul li.blog:hover,
.servicemenu.openIFrame .activated_mouseover ul li.blog:hover{
      background-position: -292px -170px;
}
/*Mail Pappas Michail 16.05.2015*/
.servicemenu ul li.jobs {
      background-position: -418px -115px;
}

.servicemenu ul li.jobs.active,
.servicemenu .disabled_mouseover ul li.jobs:hover,
.servicemenu .activated_mouseover ul li.jobs:hover {
      background-position: -418px -166px;
}
/**/

#wipe {
	position: fixed;
	right: 0px;
	top: 290px;
	z-index: 50;
	width: 32px;
	height: 32px;
}

#wipe span {
	display: block;
	width: 32px;
	height: 32px;
	background-image: url(../../images_wl2/icon-sprites.png);
	background-position: -122px -228px;
}

/*Inhalt*/
.servicemenu #side_content {
	width: 100%;
}

/*Formular*/
body.servicemenu_body {
	background:#e7e7e7;
}
.container_frame {
	width: 99%;
	height:615px;
	background:#e7e7e7;
	text-align:left;
	padding-left: 4px;
}

.servicemenu div.frame_container{
	width: 378px;
	position: absolute;
	z-index: 100;
	padding: 0px;
	background-color: #e7e7e7;
	border: 1px solid #e7e7e7;
	height: 635px;
	right: -378px;
	text-align:left;
}

.kundenschutzadresse {
	display: none;
}
/*iFrame*/
.servicemenu .frame_con {
	width: 360px;
	height: 615px;
	border: 0;
	margin: 5px;
}

.servicemenu_frame_content_div {
	margin-top: 5px;
}

/*html Div*/
.servicemenu .html_div {
	margin-left: 9px;
	margin-top: 5px;
	width: 360px;
}

.servicemenu #chatContent .html_div {
	margin-left: 18px;
	margin-top: 14px;
	width: 360px;
	margin-right: 20px;
	font-size: 13px;
}

.servicemenu h1,
.servicemenu h2,
.servicemenu_button,
.container_frame h1,
.container_frame h2 {
	font-family: wuerthextra_bold_cond, Arial, Helvetica, "Microsoft Yahei", "微软雅黑", sans-serif;
	font-weight: normal;
}

.servicemenu h1,
.container_frame h1 {
	color: #000;
	font-size: 20px;
}

.servicemenu h2,
.container_frame h2 {
	color: #959595;
	font-size: 18px;
	padding-bottom: 5px;
}

.servicemenu input,
.container_frame input {
	font-size: 15px;
	padding: 5px;
	text-transform: none;
	width: 100%;
}
.servicemenu input:hover,
.container_frame input:hover {
	cursor: pointer;
}

.servicemenu_button {
	background-color: #009ee0;
	border: medium none;
	color: #fff;
	height: 30px;
	margin-top: 20px;
	width: 100%;
}

.container_frame .icon_link {
	float: left;
	padding-top: 5px;
	padding-right: 3px;
	text-align: center;
	width: 80px;
}

.container_frame .icon_link  a {
	color: #fff;
	font-family: wuerth, Arial, Helvetica,"Microsoft Yahei","微软雅黑",sans-serif;
	font-size: 11px;
}

.container_frame img {
	/*width: 80px;*/
	background-color: #fff;
}

#webchat img {
	width: auto;
}

.container_frame .icon_link .img_text_container {
	background-color: #959595;
	display: table;
	height: 40px;
	margin: 0 auto;
	width: 80px;
}

.container_frame .icon_link .img_text {
	display: table-cell;
	padding: 1px;
	text-align: center;
	vertical-align: middle;
}

/*Servicemenu Services*/



/*Servicemenü Formular*/
#formularContent {
	overflow: hidden;
}

#formularContent .container_frame h1 {
	text-transform: uppercase;
	color:#000;
	font-family: wuerthextra_bold_cond, Arial, Helvetica, "Microsoft Yahei", "微软雅黑", sans-serif;
	font-weight: normal;
	font-size: 20px;
}

#formularContent .container_frame form.wuerthdeform div {
    padding-top: 10px;
}

#formularContent .container_frame form.wuerthdeform div.clear {
    padding-top: 0px;
}

/* Eingabefelder */
#formularContent .container_frame select,
#formularContent .container_frame input {
	font-size: 15px;
    padding: 5px;
    text-transform: none;
    width: 100%;
	border: none;
}

#formularContent .container_frame .fullColumn input  {
	box-sizing: border-box;
}

#formularContent .container_frame img {
	background-color: transparent;
}

#formularContent .container_frame textarea {
	font-family:Arial,"Microsoft Yahei","微软雅黑",sans-serif;
	font-size: 15px;
	padding: 5px;
	border:none;
	width:330px;
}

#formularContent .container_frame form div.leftColumn {
	float:left;
	width:112px;
	padding-right:25px;
}

#formularContent .container_frame form div.rightColumn {
	float:left;
	width:193px;
}

#formularContent .container_frame form label.error {
	float: left;
	line-height: 15px;
	font-size: 12px;
	width: 100%;
}

#formularContent .container_frame span.mandatory {
	font-size: 9px;
	padding: 0;
	margin: 0;
}

#formularContent .container_frame form.wuerthdeform .buttonContainer {
	padding-top: 0;
}

#formularContent .container_frame form.wuerthdeform .buttonContainer .servicemenu_button {
    background-color: #009ee0;
    border: medium none;
    color: #fff;
    font-family: wuerthextra_bold_cond,Arial,Helvetica,"Microsoft Yahei","微软雅黑",sans-serif;
    font-size: 15px;
    font-weight: normal;
    height: 30px;
    margin-top: 0px;
    width: 100%;
    text-transform: uppercase;
}

.policy_container  {
	font-size: 11px;
	padding: 5px 1px 5px 1px !important;
}

.policy_container  .cbx_policy {
	width: 10px !important;
}


/* Container mit Feldern */
#formularContent .container_frame form div h2 {
    color: #959595;
    font-family: wuerthextra_bold_cond,Arial,Helvetica,"Microsoft Yahei","微软雅黑",sans-serif;
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 5px;
}

#formularContent .container_frame h2 {
	text-transform: uppercase;
}

/* Kontakt Footer */
#formularContent .container_frame .kontakt_footer {
    clear: both;
	float: left;
    font-size: 11px;
    padding: 10px 0;
    text-transform: none;
	width: 357px;
	position: relative;
}

#formularContent .container_frame .kontakt_footer .footerLeft {
	width: 50%;
	float: left;
}

#formularContent .container_frame .kontakt_footer .footerRight {
	width: 50%;
	float: left;
}

#formularContent .container_frame .kontakt_footer  a{
	color: #000;
	cursor: pointer;
}

#formularContent .container_frame .kontakt_footer  a:hover{
	text-decoration: underline;
}
 /*
#formularContent .container_frame .kontakt_footer p {
	width: 50%;
	float: left;
}

#formularContent .container_frame .kontakt_footer p  a{
	color: #000;
}

#formularContent .container_frame .kontakt_footer p:last-child {
	clear: left;
	width: 50%;
	float: left;

}

#formularContent .container_frame .kontakt_footer p:last-child  a {
	color: #cc0000;
	float: right;
	width: 50%;
	position: absolute;
	left: 50%;
	bottom: 40px;
}
*/
#formularContent .container_frame div.column {
    float: left;
    margin-bottom: 0;
    width: 100%;
}

#servicemenu_body .container_frame div.column {
	width: 100%;
}

#formularContent .container_frame div.column p.error{
	font-weight: bold;
	width: 100%;
}

#formularContent .container_frame form {
	width: 340px;
}
	
